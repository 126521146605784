export default {
  model: {
    id: null,
    name: null,
    originalName: null,
    linkRegistration: null,
    qualificationId: null,
    qualificationIA: null,
    speatchTranscription: null,
    speatchPonctuee: null,
    campagneId: null,
    agentId: null,
    gridId: null,
    callCenterId: null,
    file: null,
    fileName: null,
    grid: [],
    pourcentage_ia: 0,
    pourcentage_scrib: 0,
    dateTime: null,
    duration: null
  },
  activeRecord: {
    id: null,
    name: null,
    originalName: null,
    linkRegistration: null,
    qualificationId: null,
    qualificationIA: null,
    speatchTranscription: null,
    speatchPonctuee: null,
    campagneId: null,
    agentId: null,
    gridId: null,
    file: null,
    grid: [],
    pourcentage_ia: 0,
    pourcentage_scrib: 0,
  },
  activeParentRecord: null,
  collection: [],
  pagination: {
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 5,
    withPagination: 1
  },
  filter: {
    keyword: '',
    startDate: null,
    endDate: null,
    withPagination: 1,
    callCenters: [],
    agents: [],
    campaigns: [],
    analyses: [],
    cellule :false
  },
  errors: null,
  isfetchingRecords: false,
  getDataSource: null,
  navigationFrom: '',
  selectedRecords: [],
  selectedNote: null
}