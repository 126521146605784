import { axios } from '../services/axios.js';
export default {
    namespaced: true,
    state: {
        model: {
            id: null,
        },
        collection: [],
        pagination: {
            total: 0,
            current_page: 1,
            last_page: 1,
            per_page: 2,
        },
    },
    mutations: {
        ADD() {
            // this mutation will add a new record to collection
        },
        DELETE() {
            // this mutation will delete record by id from collection
        },
        UPDATE() {
            // this mutation will update record by id from collection
        },
        SET_MODEL() {
            // this mutation will change the agent object value
        },
        SET_COLLECTION() {
            // this mutation will store the fetched data from server in collections state
        },
        SET_PAGINATION() {
            // this mutation will change the pagination state values
        },
    },
    actions: {
        async updateEvaluation(context, payload) {
            return await new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .put('/evaluation/' + payload.id, payload.data, config)
                    .then(async (res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async getEvaluationByUserAndRecord(context, { record_id }) {
            return await new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .get(`/evaluation?record_id=${record_id}`, config)
                    .then(async (res) => {
                        resolve(res.data.data.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    getters: {},
};
