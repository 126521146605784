import Vue from 'vue';
import Router from 'vue-router';
import BoLayout from '@/layouts/BoLayout.vue';
import globals from '../../globals.js';
Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue'),
    },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgetPassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Auth/ForgetPassword.vue'
      ),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/ResetPassword.vue'),
  },
  {
    path: '/calibration-details-guest/:code',
    name: 'calibrationdetailsguest',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Details/Calibration-details-guest.vue'
      ),
  },
  {
    path: '/join-us',
    name: 'joinUs',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/JoinUs.vue'),
  },
  {
    path: '/welcome/:token',
    name: 'welcomeJoin',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/WelcomeJoin.vue'),
  },
  {
    path: '/',
    component: BoLayout,
    children: [
      {
        path: 'teams',
        name: 'teams',
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Equipe/Equipe.vue'),
      },
      {
        path: 'teams/statistique',
        name: 'teamsStatistique',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Equipe/EquipeStat.vue'
          ),
      },
      {
        path: 'agents',
        name: 'agents',
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Agents/Agents.vue'),
      },
      {
        path: 'agents/statistique',
        name: 'agentsStatistique',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Agents/AgentsStat.vue'
          ),
      },
      {
        path: 'callcenters',
        name: 'callcenters',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/CallCenter/CallCenter.vue'
          ),
      },

      {
        path: 'campaigns',
        name: 'campaigns',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Campagnes/Campagnes.vue'
          ),
      },

      {
        path: 'qualifications',
        name: 'qualifications',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Qualification/Qualification.vue'
          ),
      },

      {
        path: 'utilisateurs',
        name: 'utilisateurs',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Utilisateur/Utilisateurs.vue'
          ),
      },

      {
        path: 'calibrations',
        name: 'calibrations',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Calibrage/Calibrage.vue'
          ),
      },

      {
        path: 'grille-evaluations',
        name: 'evaluations',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Evaluation/GrilleEvaluation.vue'
          ),
      },

      {
        path: 'records/:id?',
        name: 'records',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Records/Records.vue'
          ),
      },
      {
        path: 'recordscellules',
        name: 'recordscellules',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Records/RecordsCelulle.vue'
          ),
      },
  


      {
        path: 'settings',
        name: 'settings',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Settings/Settings.vue'
          ),
      },

      {
        path: 'dashboard',
        name: 'dashboard',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Dashboard/Dashboard.vue'
          ),
      },
      {
        path: 'details',
        name: 'details',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Details/Details.vue'
          ),
      },
      {
        path: 'calibration-details/:code',
        name: 'calibrationdetails',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Details/Calibration-details.vue'
          ),
      },
      {
        path: 'compte-rendu/:id',
        name: 'compterendu',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Details/Compte-rendu.vue'
          ),
      },
      {
        path: 'training',
        name: 'training',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Training/Training.vue'
          ),
      },
    ],
  },
  /* { path: `*`, redirect: 'dashboard' }, */
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuth = localStorage.getItem('auth') != undefined;
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let isAuthorised = globals.hasAuthorities(
    to.name,
    'navigate',
    JSON.parse(localStorage.getItem('user'))?.role
      ? JSON.parse(localStorage.getItem('user')).role
      : 'visitor',
  );
  if (to.name == 'calibrationdetailsguest' && isAuthorised) {
    next();
  } else {
    if (requiresAuth && !isAuth) next('/login');
    else if (!requiresAuth && isAuth && isAuthorised) return next('/dashboard');
    else if (isAuthorised) {
      next();
    } else next('/dashboard');
  }
});

export default router;
