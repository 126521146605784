/* eslint-disable */
import moment from 'moment/moment';

const roles = {
  roleAllowed: ['call-center',   'service-qualite', 'agent-qualite', 'supervisor-qualite'],
};

const authorities = {
  login: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'visitor',
      'responsable-activite',
      'service-qualite',
      'agent-qualite', 
      'supervisor-qualite'
    ],
  },
  register: {
    navigate: ['admin', 'super-admin', 'standard', 'call-center', 'visitor'],
  },
  forgetPassword: {
    navigate: ['admin', 'super-admin', 'standard', 'call-center', 'visitor'],
  },
  ResetPassword: {
    navigate: ['admin', 'super-admin', 'standard', 'call-center', 'visitor'],
  },
  joinUs: {
    navigate: ['visitor'],
  },
  dashboard: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
      'agent-qualite', 
      'supervisor-qualite'
    ],
  },
  callcenters: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin'],
    update: ['admin', 'super-admin'],
    archive: ['admin', 'super-admin'],
  },
  teams: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
      'agent-qualite', 
      'supervisor-qualite'
    ],
    create: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    update: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    archive: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
  },
  teamsStatistique: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    create: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    update: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    archive: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
  },
  agents: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
      'agent-qualite', 
      'supervisor-qualite'
    ],
    create: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    update: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    archive: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
  },
  agentsStatistique: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    create: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    update: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    archive: [
      'admin',
      'super-admin',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
  },
  campaigns: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin'],
    update: ['admin', 'super-admin'],
    archive: ['admin', 'super-admin'],
  },
  calibrage: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin'],
    update: ['admin', 'super-admin'],
    archive: ['admin', 'super-admin'],
    cancel: ['admin', 'super-admin'],
  },
  recordscellules: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      // 'call-center',
      // 'responsable-activite',
      // 'service-qualite',
      'agent-qualite', 
      'supervisor-qualite'
    ],
    anonymisation: ['admin', 'super-admin', 'responsable-activite', 'service-qualite'],
    create: [
      'admin',
      'super-admin',
      'agent-qualite', 
      'supervisor-qualite'
    ],
    evaluate: [
      'admin',
      'super-admin',
      'agent-qualite', 
      'supervisor-qualite'
    ],
    runCalibrate: ['admin', 'super-admin', 'standard'],
    addToFavoris: [
      'admin',
      'super-admin',
      'agent-qualite', 
      'supervisor-qualite'
    ],
  },
  records: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite'
      // 'agent-qualite', 
      // 'supervisor-qualite'
    ],
    anonymisation: ['admin', 'super-admin', 'responsable-activite', 'service-qualite'],
    create: ['admin', 'super-admin', 'responsable-activite', 'service-qualite', 'agent-qualite', 'supervisor-qualite'],
    evaluate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
    runCalibrate: ['admin', 'super-admin', 'standard'],
    addToFavoris: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'responsable-activite',
      'service-qualite',
    ],
  },
  calibrations: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin', 'standard'],
    update: ['admin', 'super-admin', 'standard'],
    cancel: ['admin', 'super-admin', 'standard'],
    evaluate: [
      'admin',
      'super-admin',
      'standard',
      'invite',
      'responsable-activite',
      'service-qualite',
    ],
  },

  enregistrements: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin', 'standard'],
    update: ['admin', 'super-admin', 'standard'],
    cancel: ['admin', 'super-admin', 'standard'],
  },
  settings: {
    navigate: ['admin', 'super-admin'],
    create: ['super-admin', 'admin'],
    update: ['super-admin', 'admin'],
    cancel: ['super-admin', 'admin'],
  },

  evaluations: {
    navigate: ['admin', 'super-admin'],
    create: ['admin', 'super-admin'],
    update: ['admin', 'super-admin'],
    cancel: ['admin', 'super-admin'],
    evaluate: [
      'admin',
      'super-admin',
      'invite',
      'responsable-activite',
      'service-qualite',
    ],
  },
  details: {
    navigate: ['admin', 'super-admin', 'standard'],
    create: ['admin', 'super-admin', 'standard'],
    update: ['admin', 'super-admin', 'standard'],
    cancel: ['admin', 'super-admin', 'standard'],
    evaluate: [
      'admin',
      'super-admin',
      'standard',
      'invite',
      'responsable-activite',
      'service-qualite',
    ],
  },
  qualifications: {
    navigate: ['admin', 'super-admin', 'standard'],
  },
  utilisateurs: {
    navigate: ['admin', 'super-admin'],
  },

  calibrationdetails: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'responsable-activite',
      'service-qualite',
    ],
    create: ['admin', 'super-admin', 'standard'],
    update: ['admin', 'super-admin', 'standard'],
    cancel: ['admin', 'super-admin', 'standard'],
    evaluate: [
      'admin',
      'super-admin',
      'standard',
      'invite',
      'responsable-activite',
      'service-qualite',
    ],
  },
  calibrationdetailsguest: {
    navigate: ['visitor'],
    evaluate: ['invite'],
  },
  joinUs: {
    navigate: [
      'admin',
      'super-admin',
      'standard',
      'call-center',
      'visitor',
      'responsable-activite',
      'service-qualite',
    ],
  },

  welcomeJoin: {
    navigate: ['visitor'],
    evaluate: ['invite'],
  },

  compterendu: {
    navigate: ['admin', 'super-admin'],
  },
  training: {
    navigate: ['admin', 'super-admin'],
    create: ['admin', 'super-admin'],
    update: ['admin', 'super-admin'],
    cancel: ['admin', 'super-admin'],
  },
};

const abbreviateName = (name) => {
  if (name.length > 10) {
    let abbreviated = name.substr(0, 10) + '...';
    abbreviated = abbreviated.charAt(0).toUpperCase() + abbreviated.slice(1);
    return abbreviated;
  } else {
    return name;
  }
};

export default {
  isRoleAllowed: (module, role) => {
    return roles[module].includes(role);
  },
  hasAuthorities: (module, action, role = 'visitor') => {
    
    return authorities[module][action].includes(role);
  },
  capitalizeFirstLetter: (str) => {
    if (str != undefined) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  },
  capitalizeAllLetters: (str) => {
    if (str != undefined) {
      if (str !== undefined) {
        return str.toUpperCase();
      }
    }
  },
  formatDate: (date) => {
    return moment(date).format('DD/MM/YYYY - HH[h]mm');
  },
  formatDateWithoutTime: (date) => {
    return moment(date).format('DD/MM/YYYY');
  },
  audioItemDateFormat: (date) => {
    return moment(date).format('DD/MM/YYYY');
  },
  audioItemTimeFormat: (date) => {
    return moment(date).format('HH[h]mm');
  },
  getTimeAgo(date) {
    return moment(date).fromNow();
  },
  abbreviateName,
};
